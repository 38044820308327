import './App.css';
import Home from "./component/home/Home";
import About from "./component/about/About";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap"

function App() {
  return (
    <>
    <Router>        
      <Navbar className="App-navigation" expand="xl">
        <Navbar.Brand></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link>
              <Link to="/" className="App-navigation-link">HOME</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/about" className="App-navigation-link">ABOUT</Link>
            </Nav.Link>
            <Nav.Link href="https://docs.brandonflude.com/me/resume" target="_blank" className="App-navigation-link">
              RESUME
            </Nav.Link>
            <Nav.Link href="https://docs.brandonflude.com/me/dissertation" target="_blank" className="App-navigation-link">
              DISSERTATION
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
    </>
  );
}

export default App;
