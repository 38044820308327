import React from 'react';
import { APP_ICONS } from '../../secret/Secrets.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Social extends React.Component {
    generateSocialLinkFromSocialObject(social) {
        return (
            <div className={"App-icon-link " + social.key} key={social.key}>
                <a href={social.link} aria-label={social.key} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={social.icon} /></a>
            </div>
        );
    }

    render() { 
        this.socialLinks = APP_ICONS.map((social, index) => (
            this.generateSocialLinkFromSocialObject(social)
        ));
        return (
            <div className="App-icons">
                {this.socialLinks}
            </div>
        );
    }
}

export default Social;
