import React from 'react';
import { API_KEY, CITY, STATE, COUNTRY } from '../../secret/Secrets.js';

class Weather extends React.Component {
    constructor() {
        super();
        this.state = {
            apiResponse: []
        }
    }

    kelvinToFarenheit(kelvin) {
        return Math.trunc(((kelvin - 273.15) * 1.8) + 32);
    }

    getImageSourceFromWeatherIcon(icon) {
        return 'https://openweathermap.org/img/wn/' + icon + '@2x.png';
    }

    componentDidMount() {
        fetch('//api.openweathermap.org/data/2.5/weather?q=' + CITY + ',' + STATE + ',' + COUNTRY + '&appid=' + API_KEY)
        .then((response) => response.json())
        .then(weatherData => {
            this.setState({ 
                apiResponse: weatherData 
            });
        });
    }

    render() {
        if(!this.state.apiResponse.base) {
            return null;
        }
        
        return (
            <div>
                <h4>Currently {this.kelvinToFarenheit(this.state.apiResponse.main.temp)}° in {this.state.apiResponse.name}</h4>
                <img src={this.getImageSourceFromWeatherIcon(this.state.apiResponse.weather[0].icon)} className="weatherIcon" alt="current weather" height="75px" width="75px" />
            </div>
        );
    }
}

export default Weather;