import React from 'react';
import './../../App.css';
import './../../Weather.css';
import Social from "./../../component/social/Social";
import { TAG_LINE } from './../../secret/Secrets.js';
import Weather from "./../../component/weather/Weather";

class About extends React.Component {
    render() {
        document.title = 'about | brandonflude';

        return (
          <div className="App">
            <header className="App-header">
              <h2 className="App-subheader">
                About Me
              </h2>
              <div className="App-block">
                <h3>{TAG_LINE}</h3>
                <h3>
                  <a href="https://bcert.me/suvuhicpg" target="_blank"><img src="https://www.scrumalliance.org/_scrum/images/Badges/SAI_BadgeSizes_DigitalBadging_CSM.png" alt="Certified Scrum Master" height="150px" width="150px" /></a>
                </h3>
              </div>
              <Weather />
              <Social />
            </header>
          </div>
        );
    }
}

export default About;
