import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faGitlab, faLinkedin, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";

export const API_KEY = 'e4502d82c10398f8b5f78cf9436a8fb1';
export const CITY = 'Kalamazoo';
export const STATE = 'MI';
export const COUNTRY = 'US';

export const TAG_LINE = 'Brandon Flude is a motivated software developer based in Kalamazoo, MI originally from Derby, England.';

export const APP_ICONS = [
    {
        icon: faGithub,
        link: "https://github.com/brandonflude",
        key: 'github'
    },
    {
        icon: faGitlab,
        link: "https://gitlab.com/brandonflude",
        key: 'gitlab'
    },
    {
        icon: faBuilding,
        link: "https://fishbowl.com",
        key: 'work'
    },
    {
        icon: faLinkedin,
        link: "https://www.linkedin.com/in/brandonflude",
        key: 'linkedin'
    },
    {
        icon: faTwitter,
        link: "https://www.twitter.com/brandonflude",
        key: 'twitter'
    },
    {
        icon: faInstagram,
        link: "https://www.instagram.com/brandonflude",
        key: 'instagram'
    }
];
