import React from 'react';
import Social from "./../../component/social/Social";
import './../../App.css';

class Home extends React.Component {
    render() {
        document.title = 'home | brandonflude';

        return (
          <div className="App">
            <header className="App-header">
              <Social />
            </header>
          </div>
        );
    }
}

export default Home;
